import styled from 'styled-components';
import Badge from "react-bootstrap/Badge";
import { DotLottiePlayer, Controls } from '@dotlottie/react-player';

export const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  z-index: 1000;
  position: relative;

  padding: 50px 0px 0px 0px;
  background: #111e12;

  @media (max-width: 1200px) {
    gap: 20px;
  }
`;

export const Button = styled.a`
  background-color: #E9FD8C; // Use the green color from the design for the button
  color: #2F5131;
  border: none;
  padding: 8px 30px;
  font-size: 2em;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s;
  border-radius: 10px;

  font-family: 'Inter', sans-serif;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  text-align: center;
  letter-spacing: -0.05em;
  border: 3px solid #E9FD8C;

  :hover {
    text-decoration: none;
    border: 3px solid #007053;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height:  ${props => props.height || '275px'};
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 1600px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.maxWidth || '780px'};

  gap: 20px;
`;

export const Card = styled.div`
  background: ${props => props.background};

  width: 100%;
  max-width: ${props => props.maxWidth || '780px'};
  height: ${props => props.height || '275px'};
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-grow: 1;
`;

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTextContainerDuration = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }
`;

export const CardHeader = styled.h1`
  display: block;
  color: white;
  font-family: "Inter";
  font-size: 33px;
  font-weight: 900;
`;

export const CardText = styled.h2`
  color: rgba(255, 255, 255, 0.8);
  font-family: "Montserrat";
  font-size: 23px
`;

export const CardImage = styled.img`
`;

export const CardImageContainer = styled.div`
  animation: appearImage 1s ease-in-out;

  @keyframes appearImage {
    from {
      transform: translateY(20px);
    }
    to {
      transform:translateY(0);
    }
  }

`;

export const ComparisonContainer = styled.div`
  background: #0E2123;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  width: 400px;
  height: 140px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: -20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);

  @media (max-width: 900px) {
    width: 300px;
    margin-bottom: 20px;
  }
`;

export const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Inter";
  font-weight: 600;
`;

export const Bar = styled.div`
  border-radius: 0px 5px 5px 0px;
  text-align: center;
  color: #1F3621;
  font-weight: 500;
  font-size: 15px;
  display: flex; // Added to enable flexbox
  align-items: center; // Vertically centers the text
  justify-content: center; // Horizontally centers the text
`;

export const CardAnimationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 1100px) {
    width: 100%;
    justify-content: space-around;
  }
`;

export const DotLottiePlayerSports = styled(DotLottiePlayer)`
  height: 200px;
  width: 200px;

  @media (max-width: 1350px) {
    height: 150px;
    width: 150px;
  }
`;

export const BookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  font-size: 25px;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border: 1px solid #49575E;
  background-color: #B8DCEF;
  color: #1D2D1D;

`;

export const AnimatedBookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  font-size: 25px;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border: 1px solid #49575E;
  background-color: #1d2d1d;
  color: #1D2D1D;

  animation: color-change ${() => `${Math.floor(Math.random()*10)+5}s`} infinite;

  @keyframes color-change {
    0% { background-color: #B8DCEF; }
    50% { 
      background-color: #E9FD8C;
    }
    100% { background-color: #B8DCEF; }
  }
`;

export const RowFirst = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 1600px;

  @media (max-width: 1400px) {
    height: 100%;
    flex-direction: column;
  }
`;

export const TopContainerRowFirst = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 66%;
  justify-content: center;
  align-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const CardLatency = styled.div`
  background: rgb(29, 45, 29);

  width: 100%;
  height: 275px;
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px 20px 0px 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (min-width: 700px) and (max-width: 1100px) {
    height: 475px;
  }

  @media (min-width: 0px) and (max-width: 475px) {
    height: 100%;
  }
`

export const CardBodyLatency = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: 700px) and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 0px) and (max-width: 475px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CardBrowser = styled.div`
  background: radial-gradient(36% 34% at 48.599999999999994% 77.30000000000001%,#415919 0%,rgb(29,45,29) 100%);

  width: 100%;
  max-width: ${props => props.maxWidth || '780px'};
  height: ${props => props.height || '275px'};
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (min-width: 700px) and (max-width: 1100px) {
    height: 475px;
    align-self: flex-start;
  }

  @media (min-width: 0px) and (max-width: 700px) {
    background: radial-gradient(46% 44% at 48.599999999999994% 57.30000000000001%,#415919 0%,rgb(29,45,29) 100%);
    height: 100%;
  }
`

export const CardBodyBrowser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: 700px) and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 0px) and (max-width: 475px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonBrowser = styled(Button)`
  @media (max-width: 700px) {
    display: none;
  }
`;


export const CardDuration = styled.div`
  background: rgb(29, 45, 29);

  width: 33%;
  height: ${props => props.height || '275px'};
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (min-width: 700px) and (max-width: 1400px) {
    width: 100%;
    height: 100%;
    flex-direction: row;
    gap: 20px;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
`

export const CardBodyDuration = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: 700px) and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 0px) and (max-width: 475px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ArbContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);

  border-radius: 10px;

  height: 150px;
  width: 400px;

  background: #111e12;

  @media (max-width: 450px) {
    width: 300px;
  }

  @media (max-width: 350px) {
    width: 250px;
  }
`;

export const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 0px 5px 10px;
`;

export const EventSport = styled.span`
  color: white;
  font-size: 16px;
`;

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: invert(100%) sepia(21%) saturate(0%) hue-rotate(4deg) brightness(108%) contrast(101%);
`

export const EventTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
`;

export const EventName = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

export const EventLeague = styled.span`
  color: #758b77;
  font-size: 16px;
`;

export const BetCardTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1px solid #324436;
  border-radius: 5px;
  height: 100%;

  .firstRow {
    flex: 0 0 80px;
    justify-content: center;
    width: 130px;
    margin-right: 7px;
    padding-top: 4px;
    padding-bottom: 4px;

    @media (max-width: 1208px) {
      width: 100px;
    }
  }

  :hover {
    cursor: pointer;
    border: ${props => !props.pinned ? '1px solid white' : ''};
  }
`

export const ProfitBadgeText = styled.span`
  font-size: medium;
  margin-left: 5px;
`

export const ElapsedTimeText = styled.span`
  margin-top: 5px;
  font-size: medium;
  text-align: center;
`

export const BookieName = styled.a`
  text-transform: capitalize;
  color: rgb(218, 255, 153);
  font-weight: bold;
  font-size: 16px;

  :hover {
    text-decoration: none;
  }
`

export const ArbColumn = styled.div`
  height: 'auto';
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 60px;
`

export const RowContentContainer = styled.div`
  height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
  gap: 0px;

  .bookie {
    display: flex;
    flex-direction: column;
    height: 100%;
    .bookie-odds {
      display: flex;
      align-items: center;
    }
  }
`

export const BetCardRow = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;

  background-color: ${props => props.idx === 1 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};

  font-size: 14px;
  font-weight: 300;
  flex-grow: ${props => props.small ? 1 : 0};

  p {
    margin-bottom: 0px;
  }

  .status {
    margin-left: 5px;
  }

  .status-circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 3px;
  }

  a:link {
    color: white;
    font-weight: bold;
  }

  a:visited {
    color: white;
  }

  :nth-child(1){
    padding-bottom: 5px;
  }

  :nth-child(2){
    padding-bottom: 5px;
  }
`

export const BetName = styled.a`
  color: white;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    color: ${props => props.href ? 'yellow' : 'white'} !important;
    text-decoration: none;
  }
`

export const BetOdds = styled.a`
  color: white;
  font-size: 18px;

  :hover {
    text-decoration: none;
  }
`

export const RowSecond = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 1600px;

  @media (max-width: 1100px) {
    height: 100%;
  }
`;

export const CardSports = styled.div`
  background: #fff;

  width: 100%;
  max-width: 1600px;
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (max-width: 1100px) {
    height: 590px;
  }

  @media (max-width: 450px) {
    height: 100%;
  }
`

export const CardBodySports = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 10px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const CardTextSports = styled.h2`
  color: rgba(34, 58, 36, 0.8);
  font-family: "Montserrat";
  font-size: 30px;
  width: 420px;

  @media (max-width: 450px) {
    width: 100%;
    font-size: 25px;
  }
`;


export const CardCalendarCustom = styled.div`
  background: #B8DCEF;

  width: 100%;
  max-width: 320px;
  height: 550px;
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (min-width: 700px) and (max-width: 1100px) {
    width: 390px;
    height: 590px;
    display: flex;
  }
`

export const RowThird = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height:  100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 1600px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CardTextContainerConfiguration = styled.div`
  display: flex;
  flex-direction: column;
  width: 620px;
  text-align: left;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
    gap: 5px;
  }

  @media (min-width: 668px) and (max-width: 1000px) {
    gap: 5px;
  }

  @media (max-width: 400px) {
    gap: 5px;
  }
`;

export const CardHeaderConfiguration = styled.h1`
  display: block;
  color: white;
  font-family: "Inter";
  font-size: 50px;
  font-weight: 900;

  @media (max-width: 650px) {
    width: 320px;
    font-size: 43px;
  }

  @media (max-width: 330px) {
    width: 280px;
    font-size: 35px;
  }
`;

export const CardTextConfiguration = styled.h2`
  color: rgba(255, 255, 255, 0.8);
  font-family: "Montserrat";
  font-size: 30px;
  width: 450px;
  text-align: center;

  @media (min-width: 668px) and (max-width: 1000px) {
    font-size: 30px;
    width: 430px;
  }

  @media (max-width: 460px) {
    font-size: 25px;
    width: 320px;
  }

  @media (max-width: 330px) {
    font-size: 25px;
    width: 280px;
  }
`;

export const CardImageConfiguration = styled.img`
  width: 100%;
  max-width: 800px;
  height: auto;
  object-fit: contain;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const CardCalendar = styled.div`
  background: #B8DCEF;

  width: 100%;
  max-width: 320px;
  height: 550px;
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (max-width: 1000px) {
    max-width: 100%;
    height: 415px;
  }


  @media (min-width: 405px) and (max-width: 530px) {
    height: 470px;
  }

  @media (min-width: 700px) and (max-width: 1100px) {
    display: none;
  }
`

export const CardBodyCalendar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const RowFourth = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 1600px;

  @media (min-width: 1100px) and (max-width: 1100px) {
    align-items: stretch;
    height: 600px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardYield = styled.div`
  background: #E9FD8C;

  width: 100%;
  max-width: 320px;
  height: 550px;
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;
  flex-direction: column;
  align-items: flex-start;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 0.5s ease-in-out;

  @media (min-width: 768px) and (max-width: 1100px) {
    height: unset;
    align-self: stretch;
  }

  @media (min-width: 550px) and (max-width: 768px) {
    max-width: 100%;
    flex-direction: row;
    height: 275px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    flex-direction: column;
    height: 100%;
  }
`

export const YieldTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;


export const ComparisonContainerYield = styled.div`
    background: #0E2123;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 15px;
    width: 200px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: -40px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);

    @media (min-width: 768px) and (max-width: 1077px) {
      margin-bottom: -60px;
    }
  `;

export const CardReach = styled.div`
  background: ${props => props.background};

  width: 100%;
  max-width: 1600px;
  height: 550px;
  border-radius: 10px;
  box-shadow: 8px 7px 8px #0000001a;
  align-items: center;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  overflow: hidden;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: appear 0.5s ease-in-out;

  .marquee {
    width: 100%;
    max-width: 1220px;
    position: relative;
    overflow: hidden;
    // mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }

  .marquee__inner {
    width: 200%; // Increase the width to accommodate the duplicated content
    display: flex;
    position: relative;
    animation: marquee 35s linear infinite;
    animation-play-state: running;

  }

  @keyframes marquee {
    0% {
        transform: translateX(0);
    }

  100% {
      transform: translateX(-100%);
    }
  }

  @media (max-width: 1100px) {
    height: 100%;
  }
`;

export const CardBodyReach = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 20px 5px 20px 5px;
  flexDirection: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TopContainerReach = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 95%;

  @media (max-width: 768px) {
    order: 1;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CardTextContainerReach = styled.div`
  display: flex;
  flex-direction: column;
  width: 620px;
  text-align: left;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardTextReach = styled.h2`
  color: rgba(34, 58, 36, 0.8);
  font-family: "Montserrat";
  font-size: 30px;
  width: 450px;

  @media (max-width: 1000px) {
    font-size: 29px;
    width: 100%;
  }
`;

export const CardHeaderReach = styled.h1`
  display: block;
  color: #1F3621;
  font-family: "Inter";
  font-size: 50px;
  font-weight: 900;
  width: 470px;

  @media (max-width: 1000px) {
    font-size: 40px;
    width: 100%;
  }
`;

export const RightTopContainerReach = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  height: 90%;

  @media (min-width: 768px) and (max-width: 1077px) {
    display: none;
  }

  @media (max-width: 768px) {
    order: -1;
  }
`;

export const DotLottiePlayerReach = styled(DotLottiePlayer)`
  height: 200px;
  width: 200px;
  margin-right: -20px;
  margin-top: -60px;

  @media (max-width: 768px) {
    height: 160px;
    width: 160px;
    margin-left: -30px;
    margin-top: -30px;
  }
`;


export const BarContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Inter";
  font-weight: 600;
`;

export const VerticalBar = styled.div`
  border-radius: 5px 5px 0px 0px;
  color: #1F3621;
  font-weight: 500;
  font-size: 18px;
  display: flex; // Added to enable flexbox
  padding: 10px 0px 0px 0px;
  align-items: flex-start; // Vertically centers the text
  justify-content: center; // Horizontally centers the text
`;

export const CheckBookiesButton = styled(Button)`
  @media (max-width: 1077px) {
    display: none;
  }
`;

export const CheckBookiesButtonMobile = styled(Button)`
  @media (min-width: 1077px) {
    display: none;
  }
`;